body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.btn-nav{
  height:37px;
  width: 150px;
  background-color: #000000;
  color: white;
  border-radius: 20px;
  font-weight: 600;
  padding-bottom: 5px;
  border-color: #000000;
  margin-right: 0px;
  margin-top: 8px;
  float: right;
}
.btn-nav:hover{
  background-color: #000000;
  border-color: #000000;
}
.section-3-txt-1{
  font-weight: 650;
  font-size: 1.6rem;
}
.section-3-txt-2{
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 15px;
}
.div-img{
  width: 100%;
  max-width: 416px;
  height: 280px;
}
.container-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-content {
  width: 100%;
  max-width: 1440px;
}
.image {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.mt-head{
  background-color:rgb(255, 255, 255);
  z-index: 10;
  box-shadow: 4px 4px 10px 0px grey;
}
.mt-home{
  margin-top: 50px;
}
.btn-section-2{
  height:37px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #000000;
  color: white;
  border-radius: 20px;
  font-weight: 600;
  border-color: #000000;
}
.btn-section-2:hover{
  background-color: #000000;
  border-color: #000000;
}

.bg-grey{
  background-color: #e9ebea;
}
.section-2-img{
  border-radius: 25px;
  width: 35%;
  height: 65%;
}
.main-div{
  width: 100%;
}
.main-img{
  width: 100%;
  height: 550px;
}
.fl-right{
  float: right;
}
.center{
  text-align: center;
}
.btm-top-crd{
  background-color: #189BA6;
  text-align: center;
  height: 70px;
  color: white;
  padding: 18px;
  font-size: 18px;
  font-weight: 650;
  cursor: pointer;
}
.sub-head{
  text-align: center;
  font-weight: 650;
  font-size: 1.6rem;
}
.btm-top-crd:hover{
  text-decoration: underline;
}
.fl-left{
  float: left;
}
.section-2-text{
  font-size: 19px;
}
.btn-ph{
  background-color: white;
  border-color: white;
  color: rgb(0, 0, 0);
  float: right;
  margin-top: 15px;
}
.btn-ph:hover{
  background-color: white;
  border-color: white;
  color: rgb(0, 0, 0);
  float: right;
  margin-top: 15px;
}
.section-3-img-div{
  height: 300px;
  width: 350px;
  border-radius: 15px;
  padding-left: 5px;
  padding-right: 5px;
}
.section-3-div{
  margin-left: 10px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.logo{
  width: 220px;
  height: 52px;
  border-radius: 10px;
}
.App-link {
  color: #61dafb;
}
.section-9-img{
  width: 100%;
  height: 90%;
  border-radius: 10px;
}
.section-9-txt-1{
  font-weight: 650;
  font-size: 1.2rem;
  padding-left: 55px;
  padding-top: 35px;
}
.section-9-logo{
  border-radius: 75px;
}
.section-10-txt{
  font-weight: 700;
  font-size: 1rem;
  padding-left: 10px;
  padding-top: 25px;
}
.abt-txt-hd{
  font-size: xx-large;
  font-weight: 700;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #000000;
}
.section-11-txt{
  font-size: 1.1rem;
  font-weight: 500;
  font-family:Arial, Helvetica, sans-serif;
}
.footer{
  background-color: #c0bfbf;
  color: white;
  margin-top: 45px;
  padding: 20px 0px 20px 0px;
}
.text-black{
  color: black;
}
.modal-header-txt{
  font-size: 28px;
  font-weight: 500;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  margin-left: auto;
  margin-right: auto;
}
.modal-btn{
  background-color: #000000;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  border-color: #000000;
  border-radius: 20px;
}
.modal-btn:hover{
  background-color: #000000;
  border-color: #000000;
}
.modal-header{
  background-color: #f0eaee;
}
.modal-body{
  background-color:white;
}
.section-3-1-img{
  border-radius: 35px;
  width: 65%;
  margin-left: 25px;
}
.section-3-1-header{
  font-size: 28px;
  font-weight: 600;
}
.section-3-1-txt{
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}
.section-3-1-txt-1{
  font-size: 18px;
  font-weight: 500;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.section-3-1-div{
  margin-left: 25px;
}
.section-3-1-bg{
  background-color: rgb(238, 238, 238);
  padding-top: 25px;
  padding-bottom: 25px;
}
.btn-section-3-0{
  height:45px;
  width: 50%;
  background-color: #000000;
  color: white;
  border-radius: 20px;
  font-weight: 600;
  border-color: #000000;
}
.btn-section-3-0:hover{
  background-color: #000000;
  border-color: #000000;
}

.display-block{
  display: block;
}
.display-none{
  display: none;
}
.section-3-0-cardtxt{
  margin-top: 10px;
  font-size: large;
  font-weight: 500;
}
.section-3-0-card{
  width: 90%;
  height: 95%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 0px #b5b6b8;
}
.section-3-0-cardhead{
  font-size: 1.4rem;
  font-weight: 600;
  font-family: "Roboto",sans-serif;
  background-color: #ffffff ;
  border-radius: 20px;
  color: #000000;
}
.section-3-0-crdbtn{
  background-color: rgb(0, 0, 0);
  border-color: rgb(0, 0, 0);
}
.section-3-0-crdbtn:hover{
  background-color: rgb(0, 0, 0);
  border-color: rgb(0, 0, 0);
}
.row-crd-1{
  padding-bottom: 0px;
}
.row-crd{
  padding-bottom: 0px;
}
.card-img{
  border-radius: 45px;
  width: 10%;
  margin-right: 20px;
}
.section-11-card{
  width: 90%;
  height: 95%;
  border-radius: 15px;
  box-shadow: 2px 2px 10px 0px #b5b6b8;
}
.section-11-crd-div{
  width: 90%;
  height: 20%;
}
.align-items-center{
  align-items: center;
}
.section-11-crd-img{
  width: 55px;
  height: 155px;
}
.section-11-txt-hd{
  font-size: 24px;
  font-family: "Roboto",sans-serif ;
  font-weight: 550;
  color: #000000;
}
.section-11-crd-txt{
  font-weight: 700;
  font-size: medium;
  color: #000000;
}
.section-abtus-img{
  width: 100px;
  height: 100px;
  border-radius: 50px;
}
.pos-fixed{
  position: fixed;
}
.whats-logo-div{
  position: fixed;
  right: 5px;
  top: 350px;
  z-index: 1;
}
.social-logo-img{
  width: 45px;
}
.facebook-logo-div{
  position: fixed;
  right: 5px;
  top: 300px;
  z-index: 1;
}
.instagram-logo-div{
  position: fixed;
  right: 5px;
  top: 400px;
  z-index: 1;
}
@media only screen and (max-width: 767px){
  .logo{
    width: 175px;
    height: 45px;
  }
}
@media only screen and (max-width: 420px){
  .section-3-img-div{
    height: 300px;
    width: 250px;
    border-radius: 20px;
    text-align: center;
  }
}
@media only screen and (max-width: 1000px){
  .mt-head{
    background-color:white;
    z-index: 2;
    height: 70px;
  }
  .main-img{
    width: 100%;
    height: 400px;
  }
  .mt-home{
    margin-top: 60px;
  }
  .section-3-1-div{
    margin-left: 15px;
  }
  .section-3-1-img{
    width: 85%;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 770px){
  .section-3-1-txt{
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
  }
  .section-3-1-txt-1{
    font-size: 19px;
    font-weight: 600;
  }
  .section-3-1-div{
    margin-left: 10px;
  }
  .section-3-1-img{
    border-radius: 25px;
    width: 95%;
  }
}
@media only screen and (max-width: 600px){
  .btn-nav{
    height:37px;
    width: 100px;
    background-color: #000000;
    color: white;
    border-radius: 20px;
    font-weight: 400;
    font-size: x-small;
    border-color: #000000;
    margin-right: 0px;
    margin-top: 8px;
    float: right;
  }
  .btn-section-2{
    height:35px;
    width: 300px;
    background-color: #000000;
    color: white;
    border-radius: 20px;
    font-weight: 300;
    border-color: #000000;
  }
  .section-3-img-div{
    height: 300px;
    width: 350px;
    border-radius: 15px;
    text-align: center;
    margin-left: 25px;
  }
  .main-img{
    width: 100%;
    height: 250px;
  }
  .section-3-1-img{
    border-radius: 25px;
    width: 25%;
  }
  .section-3-1-txt-1{
    padding-top: 10px;
  }
  .social-logo-img{
    width: 36px;
  }
  .facebook-logo-div{
    position: fixed;
    right: 5px;
    top: 310px;
    z-index: 1;
  }
  .instagram-logo-div{
    position: fixed;
    right: 5px;
    top: 390px;
    z-index: 1;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

